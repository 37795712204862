import React from 'react';
import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";

export default function useEmailPlan(onCompleted?: (data: any) => void) {
  return useMutation<any, {input: any}>(
    gql(emailMutation),
    {
      onCompleted
    }
  );
}

const emailMutation = `
mutation ($input: PlansCompareInput!) {
  generatePlanEmail(input: $input)
}
`;
