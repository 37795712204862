import React from 'react';
import './App.css';
import '../shared/helpers.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {client, setErrorHandler} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import Quote from "./MedicareAdvantage/Quote";
import Home from "./shared/components/Home";
import {SnackbarProvider} from "notistack";
import {KeycloakProvider} from "../shared/KeycloakContext";
import {keycloak} from "../shared/keycloak";
import {ProfileContextProvider} from "./shared/ProfileContextProvider";
import MedigapQuote from "./Medigap/MedigapQuote";
import * as QueryString from "querystring";
import {email} from "@pact-foundation/pact/dsl/matchers";
import {getStoredUser, setStoredUser} from "../shared/StoredUser";
import {getCId, getUId} from "../shared/utils";
import {Gender} from "../medigap-types";
import moment from "moment";

function App() {
  const params = QueryString.parse(document.location.search);

  React.useEffect(() => {
    if (params['?showFlow']) {
      sessionStorage.setItem('showFlow', 'true')
    }
  }, [params]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setStoredUser({
      ...getStoredUser(),
      quoteId: urlParams.get('quote') || getStoredUser()?.quoteId,
      medigapQuoteId:
        urlParams.get('medigapQuote') || getStoredUser()?.medigapQuoteId,
      zip: urlParams.get('zip') || getStoredUser()?.zip,
      countyName: urlParams.get('county') || getStoredUser()?.countyName,
      firstName: urlParams.get('first_name') || getStoredUser()?.firstName,
      lastName: urlParams.get('last_name') || getStoredUser()?.lastName,
      tobacco: urlParams.get('tobacco') || getStoredUser()?.tobacco,
      birthDate:
        urlParams.get('dob') && moment(urlParams.get('dob')).isValid()
          ? moment(urlParams.get('dob')).format('YYYY-MM-DD')
          : getStoredUser()?.birthDate,
      phoneNumber: urlParams.get('phone') || getStoredUser()?.phoneNumber,
      email: urlParams.get('email') || getStoredUser()?.email,
      gender: urlParams.has('gender')
        ? urlParams.get('gender') === 'female'
          ? Gender.F
          : Gender.M
        : getStoredUser()?.gender,
      profileSource: urlParams.get('source') || getStoredUser()?.profileSource,
    });
    if ((window as any).hj) {
      (window as any).hj('identify', getCId(), {
        email: getStoredUser()?.email,
        c_id: getCId(),
        uid: getUId(),
      })
    }
  }, []);

  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: any) => {
          console.error(
            `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          )
        }
      );
  });

  return (
    <ApolloProvider client={client}>
      <KeycloakProvider keycloak={keycloak}>
        <ProfileContextProvider>
          <SnackbarProvider preventDuplicate classes={{
            variantSuccess: 'success-snack'
          }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3}>
            <BrowserRouter>
              <Switch>
                <Route exact path={paths.home + 'initiate-survey'} component={Home} />
                <Route path={paths.MAquestionnaire + '/:routeName'} component={Quote} />
                <Route path={paths.MGquestionnaire + '/:routeName'} component={MedigapQuote} />
                <Route exact path={paths.home}>
                  <Redirect to={paths.MGquestionnaire + '/step2'} />
                </Route>
                <Route>
                  <Redirect to={paths.MGquestionnaire + '/step2'} />
                </Route>
              </Switch>
            </BrowserRouter>
          </SnackbarProvider>
        </ProfileContextProvider>
      </KeycloakProvider>
    </ApolloProvider>
  );
}

export default App;
