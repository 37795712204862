import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {client} from "../../shared/EnrollmentApolloClient";

export default function useSaveEnrollment(onCompleted?: (data: any) => void) {
  return useMutation<{saveEnrollment: string}, {data: any}>(
    gql(saveEnrollmentMutation),
    {
      onCompleted,
      client: client
    }
  );
}

const saveEnrollmentMutation = `
mutation ($data: EnrollmentInput!) {
  saveEnrollment(input: $data)
}
`;
