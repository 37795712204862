import * as QueryString from "query-string"

export function getClientId(): string | undefined {
  const params = QueryString.parse(document.location.search);

  if (params.clientId) {
    sessionStorage.setItem('clientId', params.clientId as string)
  }

  const clientId = (params.clientId || sessionStorage.getItem('clientId')) as string

  return clientId;
}

export function removeClientId() {
  sessionStorage.removeItem('clientId')
}
