import React, {PropsWithChildren} from "react";
import {Box, Grid} from "@material-ui/core";
import EnrollmentStepper from "./component/EnrollmentStepper";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import paths from "./config/router-paths";
import Enroll from "./Enroll";
import Receive from "./Receive";
import Layout from "../shared/Layout";
import CreateAccount from "./CreateAccount";
import EnrollmentForm1 from "./EnrollmentForm1";
import EnrollmentForm2 from "./EnrollmentForm2";
import EnrollmentForm3 from "./EnrollmentForm3";
import EnrollmentForm4 from "./EnrollmentForm4";
import EnrollmentForm5 from "./EnrollmentForm5";

export default (props: RouteComponentProps<{ countyName?: string }>) => {

  return <Layout SideComponent={EnrollmentStepper} {...props}>
    <div className={'h-8'} />
    <Box alignItems={"center"} display={'flex'} flex={1} flexDirection={'column'}>
      <Switch>
          <Route exact path={paths.receive + '/:planId/:zip/:countyName/:quoteId?'} component={Receive} />
          <Route exact path={paths.enroll + '/:planId/:zip/:countyName/:quoteId?'} component={Enroll} />
          <Route exact path={paths.personalDetails + '/:planId/:zip/:countyName/:quoteId?'} component={EnrollmentForm1} />
          <Route exact path={paths.personalDetails2 + '/:planId/:zip/:countyName/:quoteId?'} component={EnrollmentForm2} />
          <Route exact path={paths.personalDetails3 + '/:planId/:zip/:countyName/:quoteId?'} component={EnrollmentForm3} />
          <Route exact path={paths.personalDetails4 + '/:planId/:zip/:countyName/:quoteId?'} component={EnrollmentForm4} />
          <Route exact path={paths.personalDetails5 + '/:planId/:zip/:countyName/:quoteId?'} component={EnrollmentForm5} />
          <Route exact path={paths.createAccount + '/:planId/:zip/:countyName/:quoteId?'} component={CreateAccount} />
      </Switch>
    </Box>
  </Layout>
}

